<template>
  <div id="discuss">
    <div class="classify">
      <div class="left">
        <span class="status">状态：</span>
        <span
          @click="setStatusAct(0, null)"
          :class="statusAct === 0 ? 'act' : 'statusList'"
          >全部</span
        >
        <!--        <span-->
        <!--          @click="setStatusAct(1, '已审核')"-->
        <!--          :class="statusAct === 1 ? 'act' : 'statusList'"-->
        <!--        >已审核</span-->
        <!--        >-->
        <span
          @click="setStatusAct(1, 1)"
          :class="statusAct === 1 ? 'act' : 'statusList'"
          >已发布</span
        >
        <span
          @click="setStatusAct(2, 0)"
          :class="statusAct === 2 ? 'act' : 'statusList'"
          >审核中</span
        >
        <span
          @click="setStatusAct(3, 2)"
          :class="statusAct === 3 ? 'act' : 'statusList'"
          >未通过</span
        >
      </div>
      <div class="right">
        <span class="time">时间：</span>
        <DatePicker
          :value="Timevalue"
          format="yyyy/MM/dd"
          @on-change="handleChange"
          type="daterange"
          split-panels
          placement="bottom-end"
          placeholder="Select date"
          style="width: 220px"
        ></DatePicker>
        <span class="time">标题：</span
        ><Input
          v-model="search"
          placeholder="请输入标题进行搜索"
          class="searchinput"
        >
          <Icon @click="handleClick" type="ios-search" slot="suffix" />
        </Input>
        <input type="text" class="elHidden" />
      </div>
    </div>
    <div class="box">
      <Spin size="large" fix v-show="spinShow"></Spin>
      <div class="list" v-for="item in list" :key="item.id">
        <div class="top">
          <div class="title">{{ item.title }}</div>
          <div class="from">发布于-{{ item.circleName }}</div>
          <div class="time">{{ item.createDate | time }}</div>
        </div>
        <div class="middle">
          <div class="middleLeft">{{ item.content }}</div>
          <div class="edit">
            <template v-if="item.status === 1 || item.status === 11">
              <div class="right">
                <Button v-throttleClick class="btn" @click="goDiscuss(item)">查看详情</Button>
                <div @click="del(item)" class="del">删除</div>
              </div>
            </template>
            <template v-if="item.status === 0">
              <div class="right">
                <div
                  style="margin-left: 0"
                  class="none"
                  @click="handleEdit(item)"
                >
                  修改
                </div>
                <div class="none">删除</div>
              </div>
            </template>
            <template v-if="item.status === 2">
              <div class="right">
                <div class="edti" @click="handleEdit(item)">修改</div>
                <div @click="del(item)" class="del">删除</div>
              </div>
            </template>
            <template v-if="item.status === 10">
              <div class="right">
                <div class="edti" @click="handleEdit(item)">修改</div>
                <div @click="del(item)" class="del">删除</div>
              </div>
            </template>
          </div>
        </div>
        <div class="bottom">
          <span class="type" :style="item.status | colored"
            >{{ item.status | type
            }}<span
              :style="item.status | colored"
              style="margin-left: 10px"
              class="yy"
              v-if="item.status === 2"
              ><span>原因：</span><span>{{ item.reason }}</span></span
            ><span
              :style="item.status | colored"
              style="margin-left: 10px"
              class="num"
              v-if="item.status === 1"
              ><span>回答：{{ item.replyNum }}</span
              ><span>阅读：{{ item.readNum }}</span
              ><span>关注：{{ item.attentionNum }}</span></span
            ></span
          >
        </div>
        <!--        {{ item }}-->
      </div>
      <div class="no-results" v-show="total === 0 && !spinShow">
        <img :src="require('@/assets/qietu/results.png')" alt="" />
        <p>暂无讨论</p>
      </div>
      <Paging
        :total="total"
        :curPage="page"
        :limit="10"
        @changePager="handlePager"
      ></Paging>
    </div>
  </div>
</template>

<script>
import { delDiscuss, scholarDiscussList } from "../../../../../apis/user";
import Paging from "@/components/paging/index.vue";
export default {
  components: {
    Paging,
  },
  name: "discuss",
  data() {
    return {
      statusAct: 0,
      Timevalue: [null, null],
      page: 1,
      search: null,
      status: null,
      total: 0,
      list: [],
      spinShow: false,
    };
  },
  created() {
    this.getList();
  },
  filters: {
    time(times) {
      let date = new Date(times);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    type(value) {
      if (value === 0) return `审核中`;
      if (value === 1) return `已发布`;
      if (value === 2) return `未通过`;
      if (value === 10) return `讨论关闭`;
    },
    colored(num) {
      if (num === 2) {
        return "color:#F32048;";
      } else if (num === 10) {
        return "color:#F32048;";
      } else if (num === 0) {
        return "color:#FD8230;";
      } else if (num === 1) {
        return "color:#999999;";
      }
    },
  },
  methods: {
    handleEdit(item) {
      console.log(item);
      let url = this.$router.resolve({
        path: "/circledetails",
        query: {
          id: item.circleId,
          title: item.title,
          content: item.content,
          myId: item.id,
        },
      });
      window.open(url.href, "_blank");
    },
    goDiscuss(item) {
      if (item.status == 11) {
        this.$Message.warning('该学术圈已不存在');
        return
      }
      let url = this.$router.resolve({
        path: "/discussdetails",
        query: {
          id: item.id,
        },
      });
      window.open(url.href, "_blank");
    },
    handlePager(page) {
      this.page = page;
      this.getList();
    },
    async del(item) {
      await delDiscuss({ id: item.id });
      await this.getList();
    },
    async getList() {
      let obj = {
        pageNo: this.page,
        pageSize: 10,
        title: this.search,
        startTime: this.Timevalue[0],
        endTime: this.Timevalue[1],
        status: this.status,
      };
      this.spinShow = true;
      const { data } = await scholarDiscussList(obj);
      let { total } = data;
      this.total = Number(total);
      this.list = data.list;
      this.spinShow = false;
    },
    setStatusAct(i, status) {
      this.statusAct = i;
      this.status = status;
      this.page = 1;
      this.getList();
    },
    handleClick() {
      this.page = 1;
      this.getList();
    },
    handleChange(value) {
      this.Timevalue = [...value];
      this.page = 1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
#discuss {
  margin-left: 40px;
  margin-right: 40px;

  .classify {
    display: flex;
    height: 30px;
    justify-content: space-between;
    width: 100%;

    .searchinput {
      width: 260px;
      border-radius: 10px;
    }

    .right {
      height: 30px;

      .time {
        //background: yellow;
        line-height: 30px;
        margin-right: 5px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }

      .elHidden {
        width: 0;
        opacity: 0;
      }
    }

    .left {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      cursor: pointer;

      .status {
        margin-right: 3px;
      }

      .statusList {
        margin-left: 10px;
        margin-right: 10px;
      }

      .act {
        margin-left: 10px;
        margin-right: 10px;
        color: #00a4ff;
      }
    }
  }
  .box:hover .title {
    color: #00a4ff;
  }
  .box {
    position: relative;
    margin-top: 40px;
    .list {
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px dashed #d6d6d6;
      border-bottom: 1px dashed #d6d6d6;
      .bottom {
          text-align: justify;
        span {
          text-align: justify;
        }
      }
      .num {
        span {
          margin: 0 10px;
        }
      }
      .top {
        display: flex;
        width: 100%;

        .title {
          width: 280px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 20px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }

        .from {
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 20px;
          color: #333333;
          margin-left: 40px;
        }
        .time {
          line-height: 20px;
          margin-left: 30px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
        }
      }
      .middle {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        //background: pink;
        .middleLeft {
          width: 1042px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 20px;
        }

        .right {
          display: flex;

          .edti {
            cursor: pointer;
            width: 82px;
            color: #00a4ff;
            height: 34px;
            border: 1px solid #00a4ff;
            border-radius: 10px;
            text-align: center;
            line-height: 34px;
          }
          .btn {
            padding: 0;
            width: 82px;
            color: #00a4ff;
            height: 34px;
            border: 1px solid #00a4ff;
            border-radius: 10px;

          }

          .none {
            margin-left: 30px;
            cursor: pointer;
            color: #999999;
            width: 82px;
            height: 34px;
            border: 1px solid #999999;
            border-radius: 10px;
            //margin-left: 30px;
            text-align: center;
            line-height: 34px;
          }

          .del {
            margin-left: 30px;
            cursor: pointer;
            color: #f32048;
            //margin-right: 20px;
            text-align: center;
            line-height: 34px;
            width: 82px;
            // margin-right: 60px;
            height: 34px;
            border: 1px solid #f32048;
            border-radius: 10px;
          }
        }
      }
    }
    .no-results {
      margin: 0 auto;
      width: 500px;
      // height: 300px;
      img {
        width: 100%;
      }
      p {
        text-align: center;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>
